import React, { Suspense, lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { history } from './redux/configureStore';
import Loader from './cruxComponents/Loader';
import ScrollToTop from './ScrollToTop';
import GetNewUID from './Pages/GetNewUID';
import UnSubscribed from './Pages/Unsubscribed';

// const GetNewUID = lazy(() => import('./Pages/GetNewUID'));
const NewQuestions = lazy(() => import('./Pages/Questions/NewQuestions'));
const QuoteDataCollectionPage = lazy(() =>
  import('./Pages/QuoteDataCollectionPage')
);
const PageNotFound = lazy(() => import('./Pages/PageNotFound'));
const PDFDownloadLink = lazy(() => import('./Pages/PDFDownloadLink'));
const SSORedirection = lazy(() => import('./Pages/SSORedirection'));
const DeathClaim = lazy(() => import('./Pages/DeathClaim'));
const ClaimsForm = lazy(() => import('./Pages/ClaimsForm'));

const env = process.env.REACT_APP_NODE_ENV;
const deathClaimRoute = env === 'production' ? '/file-a-claim' : '/deathclaim';

const AppRouter = () => {
  return (
    <>
      <ConnectedRouter history={ history } noInitialPop>
        <ScrollToTop>
          <Suspense
            fallback={
              <>
                <Loader />
              </>
            }
          >
            <Switch>
              <Route
                path={ [
                  '/quote',
                  '/plan',
                  '/timeout',
                  '/static',
                  '/quotereview',
                  '/signature',
                  '/questions/:id',
                  '/review',
                  '/payment',
                  '/challenge',
                  '/riders',
                  '/completed',
                  '/knockout',
                  '/products',
                  '/launch',
                  '/:clubCode/Enroll',
                  '/Enroll',
                  '/confirmation',
                ] }
                component={ NewQuestions }
              />
              <Route path={ ['/unsubscribed'] } component={ UnSubscribed } />
              <Route path={ ['/processing', '/interview'] } component={ Loader } />
              <Route path={ ['/login'] } component={ QuoteDataCollectionPage } />
              <Route path={ ['/login2'] } component={ QuoteDataCollectionPage } />
              <Route path={ ['/view-document'] } component={ PDFDownloadLink } />
              <Route exact path={ ['/init'] } component={ GetNewUID } />
              <Route
                exact
                path={ ['/ssodone', '/authError', '/traits', '/traits/'] }
                component={ SSORedirection }
              />
              <Route exact path={ deathClaimRoute } component={ ClaimsForm } />
              <Route exact path="/claimsform" component={ DeathClaim } />
              <Route exact path="*" component={ PageNotFound } />
            </Switch>
          </Suspense>
        </ScrollToTop>
      </ConnectedRouter>
    </>
  );
};

export default AppRouter;
